import Select2Controller from "./select2_controller"

export default class extends Select2Controller {
  searchParameters(params) {
    const term = params.term

    return {
      q: { name_cont: term },
      page: params.page || 1
    }
  }
}
