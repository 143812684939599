import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['source', 'target']

  connect() {
    this.sourceTarget.addEventListener('change', () => {
      this.targetTarget.value = this.sourceTarget.value
    })
  }
}
