import { Controller } from "stimulus"
import axios from "axios"
import qs from "qs"

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
export default class extends Controller {
  connect() {
    if (typeof window.AMap !== "object") return

    this.createMap()
  }

  remove() {
    if (this.map) this.map.destroy()
  }

  async createMap() {
    this.map = new AMap.Map("map", {
      mapStyle: 'amap://styles/d0f63dc484104ccd29c9f7585603e48e'
    })

    this.markers = {}
    this.lines   = {}
    this.users   = {}

    const sectionId = this.data.get("sectionId");
    const q = { section_id_eq: sectionId };

    let resp = await axios.get(`/lots?${qs.stringify({ q })}`)
    let data = resp.data.data

    data.forEach(lot => {
      const position = this.getPosition(lot)
      const onClick  = () => this.openInfoWindow(position, `/lots/${lot.id}`)
      const marker   = this.createMarker(position, '停车场.png', lot.attributes.name, onClick)

      const location = lot.attributes.location
      const line = this.createLine(location)

      this.map.add(marker)
      this.map.add(line)

      this.markers[lot.id] = marker
      this.lines[lot.id] = line
    })

    resp = await axios.get('/organization_accounts.json')
    data = resp.data.data
    data.forEach(it => {
      if (it.current_position) {
        const position = [it.current_position.longitude, it.current_position.latitude]
        const onClick  = () => this.openInfoWindow(position, `/organization_accounts/${it.id}`)
        const marker   = this.createMarker(position, '收费员.png', it.name, onClick)
        this.map.add(marker)
      }
    })

    this.map.setFitView(Object.values(this.markers).concat(Object.values(this.lines)))
  }

  async openInfoWindow(position, url) {
    const resp    = await axios.get(url)
    const content = resp.data

    const infoWindow = new AMap.InfoWindow({
      content: content,
      anchor: 'bottom-center',
      offset: new AMap.Pixel(0, -20)
    })

    infoWindow.open(this.map, position)
  }

  centerLot(event) {
    const id     = parseInt(event.currentTarget.value || event.currentTarget.dataset.lotId)
    const marker = this.markers[id]
    this.map.setFitView(marker)
  }

  createMarker(position, image, label, onClick = null) {
    const icon = new AMap.Icon({
      size: new AMap.Size(24, 24),
      image: imagePath(`./${image}`),
      imageSize: new AMap.Size(24, 24)
    })

    const marker = new AMap.Marker({ icon })

    if (onClick) marker.on('click', onClick)
    marker.setPosition(position)
    marker.setLabel({
      direction: 'top',
      // offset: new AMap.Pixel(20, 20),
      content: label
    })

    return marker
  }

  createLine(location) {
    const options = {
      path: location,
      isOutline: true,
      outlineColor: "#FFEEFF",
      borderWeight: 1,
      strokeColor: "#3F96FA",
      strokeOpacity: 0.5,
      strokeWeight: 3,
      strokeStyle: "dotted",
      strokeDasharray: [10, 5],
      lineJoin: "round",
      lineCap: "round",
      zIndex: 50
    }

    return new AMap.Polyline(options)
  }

  getPosition(lot) {
    const longitude = lot.attributes.longitude
    const latitude = lot.attributes.latitude
    return [longitude, latitude]
  }
}
