import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  connect() {
    const url = this.data.get('url')
    if (!!!url) return

    this.load(url)
  }

  refresh(event) {
    const url = event.currentTarget.dataset.url || this.data.get('url')
    if (!!!url) return

    this.data.set('url', url)
    this.load(url)
  }

  load(url) {
    axios.get(url, { headers: { 'Accept': 'text/html' } })
         .then(response => response.data)
         .then(html => this.element.innerHTML = html)
  }
}
