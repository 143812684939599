import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [
    'hourlyTransactions',
    'dailyTransactions',
    'lotMonthlyTransactions',
  ]

  connect() {
    this._data = window.__HOME_DATA;

    this._resizeContainers();
    this._hourlyTransactionsChart = echarts.init(this.hourlyTransactionsTarget);
    this._dailyTransactionsChart = echarts.init(this.dailyTransactionsTarget);
    this._lotMonthlyTransactionsChart = echarts.init(this.lotMonthlyTransactionsTarget);
    this._renderHourlyTransactionsCharts();
    this._renderDailyTransactionsCharts();
    this._renderLotMonthlyTransactionsChart();

    window.addEventListener('resize', () => this._resizeCharts());
  }

  _resizeCharts() {
    this._hourlyTransactionsChart.resize();
    this._dailyTransactionsChart.resize();
    this._lotMonthlyTransactionsChart.resize();
  }

  _resizeContainers() {
    const lot_count = this._data.monthly_lots.length;
    this.lotMonthlyTransactionsTarget.style.height = `${100 + lot_count * 30}px`;
  }

  _renderHourlyTransactionsCharts() {
    const map = new Map(
      this._data.hourly_transactions.map(it => [it.hour, it]),
    );
    const source = []
    for (let hour = 0; hour < 24; hour++) {
      const item = map.get(hour);
      item ? source.push(item) : source.push({hour, amount: 0});
    }
    const option = {
      dataset: {
        source: source,
        dimensions: ['hour', 'amount'],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          const param = params[0];
          const hour = param.value.hour;
          const title = `${String(hour).padStart(2, '0')}:00 ~ ${String(hour + 1).padStart(2, '0')}:00`
          return `
              <div style="font-size: 14px;">
                  <div><strong>${title}</strong></div>
                  <div style="margin-top: 10px;">${param.marker}${param.seriesName}: ${numberToCurrency(param.value.amount)}</div>
              </div>
          `;
        },
      },
      title: {
        top: 5,
        left: 'center',
        text: '今日每小时营收流水',
      },
      grid: {
        top: 60,
        left: 10,
        right: 10,
        bottom: 0,
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
        }
      ],
      series: [
        {
          name: '营收',
          type: 'bar',
          barWidth: '75%',
          color: getComputedStyle(this.element).getPropertyValue('--volcano-6'),
          markLine: {
            symbol: 'none',
            label: {
              position: 'middle',
              formatter: function (params) {
                return `最大值: ${numberToCurrency(params.value)}`;
              }
            },
            data: [{type: 'max'}],
          },
        },
      ],
    };
    this._hourlyTransactionsChart.setOption(option);
  }

  _renderDailyTransactionsCharts() {
    const date = new Date(this._data.date);
    const monthDays = getDaysInMonth(date.getFullYear(), date.getMonth());
    const map = new Map(
      this._data.daily_transactions
        .map(it => ({day: new Date(it.date).getDate(), amount: it.amount}))
        .map(it => [it.day, it]),
    );
    const source = [];
    for (let day = 1; day <= monthDays; day++) {
      const item = map.get(day);
      item ? source.push(item) : source.push({day: day, amount: 0})
    }
    const option = {
      dataset: {
        source: source,
        dimensions: ['day', 'amount'],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          const param = params[0];
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = param.value.day;
          const title = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
          return `
              <div style="font-size: 14px;">
                  <div><strong>${title}</strong></div>
                  <div style="margin-top: 10px;">${param.marker}${param.seriesName}: ${numberToCurrency(param.value.amount)}</div>
              </div>
          `;
        },
      },
      title: {
        top: 5,
        left: 'center',
        text: '当月每日营收流水',
      },
      grid: {
        top: 60,
        left: 10,
        right: 10,
        bottom: 0,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {type: 'value'},
      series: [
        {
          name: '营收',
          type: 'bar',
          barWidth: '75%',
          color: getComputedStyle(this.element).getPropertyValue('--gold-6'),
          markLine: {
            symbol: 'none',
            label: {
              position: 'middle',
              formatter: function (params) {
                return `最大值: ${numberToCurrency(params.value)}`;
              }
            },
            data: [{type: 'max'}],
          },
        }
      ]
    };
    this._dailyTransactionsChart.setOption(option);
  }

  _renderLotMonthlyTransactionsChart() {
    const option = {
      dataset: [
        {
          source: this._data.monthly_lots,
          dimensions: ['lot_name', 'amount'],
        },
        {
          transform: {
            type: 'sort',
            config: {dimension: 'amount', order: 'asc'},
          },
        }],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        show: false,
      },
      grid: {
        top: 10,
        left: 10,
        right: 60,
        bottom: 0,
        containLabel: true
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
      },
      series: [
        {
          name: '营收',
          datasetIndex: 1,
          type: 'bar',
          label: {
            show: true,
            position: 'right',
            formatter: (params) => numberToCurrency(params.value.amount),
          },
        },
      ]
    };
    // 使用刚指定的配置项和数据显示图表。
    this._lotMonthlyTransactionsChart.setOption(option);
  }
}

function getDaysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

function numberToCurrency(amount) {
  return `¥${Number(amount).toFixed(2)}`;
}