import { Controller } from "stimulus"
import axios from "axios"
import PerfectScrollbar from "perfect-scrollbar"

export default class extends Controller {
  connect() {
    this.currentPage = 1
    this.maxPage = 1
    this.fetch()

    this.scrollbar = new PerfectScrollbar(this.element, {
      wheelPropagation: false
    })
    this.element.addEventListener("ps-y-reach-end", () => this.fetch())
  }

  fetch() {
    if (this.currentPage > this.maxPage) return;

    const url = this.data.get('url')
    const container = this.element

    axios
      .get(url, { params: { page: this.currentPage } })
      .then(response => {
        this.maxPage = parseInt(response.data.meta.pagination.total)

        response.data.data.forEach((item) => {
          let node = this.mapItemToNode(item.attributes)
          container.appendChild(node)
        })

        this.scrollbar.update()
        this.currentPage += 1
      })
  }

  mapItemToNode(item) {
    let node = document.createElement('div')

    node.classList.add("m-widget3__item")
    node.innerHTML = `<div class="m-widget3__header">
                        <div class="m-widget3__user-img">
                          <img src="${item.emitter_avatar_url}" class="m-widget3__img">
                        </div>
                        <div class="m-widget3__info">
                          <span class="m-widget3__username">${item.emitter_name}</span>
                          <br>
                          <span class="m-widget3__time">${item.event_time_description}</span>
                        </div>
                      </div>
                      <div class="m-widget3__body">
                        <p class="m-widget3__text">${item.event_digest}</p>
                      </div>`
    return node
  }
}
