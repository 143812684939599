import { Controller } from "stimulus"
import c3 from "c3"

export default class extends Controller {
  static targets = [
    "chart",
  ]

  supportedColors = {
    primary: mApp.getColor('primary'),
    success: mApp.getColor('success'),
    focus: mApp.getColor('focus'),
    danger: mApp.getColor('danger'),
    accent: mApp.getColor('accent'),
    warning: mApp.getColor('warning'),
    info: mApp.getColor('info'),
    metal: mApp.getColor('metal')
  }

  connect() {
    this.buildBarChart()
  }

  buildBarChart() {
    const title = '绩效统计'
    const json  = JSON.parse(this.chartTarget.dataset.json)

    return c3.generate({
      bindto: this.chartTarget,
      data: {
        json: json,
        types: {
          "count": "bar",
          "total_paid": "bar",
          // "percentage": "line"
        },
        keys: {
          x: "account_name",
          value: [
            "count",
            "total_paid",
            // "percentage"
          ]
        },
        names: {
          count: "抄单量（车次）",
          total_paid: "实收总计（元）",
          // percentage: "实收比率（%）"
        },
        colors: {
          count: this.supportedColors.primary,
          total_paid: this.supportedColors.success,
          // percentage: this.supportedColors.focus
        },
        axes: {
          count: "y",
          total_paid: "y",
          // percentage: "y2"
        }
      },
      size: {
        height: json.length * 32 + 100
      },
      bar: {
        width: 8
      },
      axis: {
        rotated: true,
        x: {
          type: "category"
        },
        // y2: {
        //   show: true,
        //   label: "实收比率",
        //   min: 0,
        //   tick: {
        //     format: function(value) {
        //       return `${value}%`
        //     }
        //   }
        // },
      }
    })
  }
}
