import { Controller } from "stimulus"
import c3 from "c3"

export default class extends Controller {
  static targets = [
    "barChart",
    "lineChart",
    "pieChart"
  ]

  supportedColors = {
    primary: mApp.getColor('primary'),
    success: mApp.getColor('success'),
    focus: mApp.getColor('focus'),
    danger: mApp.getColor('danger'),
    accent: mApp.getColor('accent'),
    warning: mApp.getColor('warning'),
    info: mApp.getColor('info'),
    metal: mApp.getColor('metal')
  }

  connect() {
    this.barChart = this.buildBarChart(this.barChartTarget, "每日收入")
    this.lineChart = this.buildLineChart(this.lineChartTarget, "支付率")
    this.pieChart = this.buildPieChart(this.pieChartTarget, "累计周转")
    this.refresh()
  }

  sum(data, field) {
    return data.reduce((sum, it) => (sum += parseFloat(it[field])), 0)
  }

  refresh() {
    const data = JSON.parse(this.element.dataset.json)

    // Load count bar chart
    let options = {
      json: data,
      keys: {
        x: "date",
        value: [
          "paid_amount",
          "collect_back_amount",
          "on_site_amount"
        ],
      },
      keys: {
        x: "date",
        value: [
          "paid_amount",
          "collect_back_amount",
          "on_site_amount"
        ],
      },
      colors: {
        paid_amount: this.supportedColors.success,
        collect_back_amount: this.supportedColors.primary,
      },
      names: {
        paid_amount: "抄单",
        collect_back_amount: "追缴",
        on_site_amount: "当场收取"
      },
    }
    this.barChart.load(options)
    this.barChart.groups([["paid_amount", "collect_back_amount", "on_site_amount"]])

    // Load count line chart
    options = {
      json: data,
      colors: {
        paid_count_percent: this.supportedColors.primary,
        count: this.supportedColors.success
      },
      keys: {
        x: "date",
        value: [
          "count",
          "paid_count_percent",
        ],
      },
      names: {
        count: '抄单量',
        paid_count_percent: "支付率",
      },
      axes: {
        paid_count_percent: 'y2',
        count: 'y'
      },
      type: "line",
      types: {
        count: 'bar',
        paid_count_percent: 'line'
      }
    }
    this.lineChart.load(options)

    // Load count pie chart
    options = {
      json: [
        {
          paid_amount_total: this.sum(data, 'paid_amount'),
          collect_back_amount_total: this.sum(data, 'collect_back_amount'),
          on_site_amount_total: this.sum(data, 'on_site_amount')
        },
      ],
      colors: {
        paid_amount_total: this.supportedColors.success,
        collect_back_amount_total: this.supportedColors.primary,
        on_site_amount_total: this.supportedColors.focus
      },
      keys: {
        value: ["paid_amount_total", "collect_back_amount_total", "on_site_amount_total"],
      },
      names: {
        paid_amount_total: "抄单",
        collect_back_amount_total: "追缴",
        on_site_amount_total: "当场收取"
      },
    }
    this.pieChart.load(options)
  }

  buildBarChart(element, title) {
    return c3.generate({
      bindto: element,
      data: {
        order: null,
        json: [],
        type: "bar",
      },
      bar: {
        title: title,
      },
      axis: {
        y: {
          label: title
        },
        x: {
          type: "timeseries",
          tick: {
            format: "%Y-%m-%d",
            culling: {
              max: 3
            }
          },
        },
      },
    })
  }

  buildLineChart(element, title) {
    return c3.generate({
      bindto: element,
      data: {
        x: "date",
        type: "line",
        json: [],
      },
      line: {
        title: title,
      },
      axis: {
        y: {
          label: '抄单量',
          min: 0,
          padding: {
            bottom: 0
          }
        },
        y2: {
          show: true,
          label: title,
          min: 0,
          padding: {
            bottom: 0
          },
          tick: {
            format: function(value) {
              return `${value}%`
            }
          }
        },
        x: {
          type: "timeseries",
          tick: {
            format: "%Y-%m-%d",
            culling: {
              max: 3
            }
          }
        },
      },
      tooltip: {
        format: {
          value: function(value, ratio, id, index) {
            if (id == 'count') return value
            return `${value}%`
          }
        }
      }
    })
  }

  buildPieChart(element, title) {
    return c3.generate({
      bindto: element,
      data: {
        json: [],
        type: "pie",
      }
    })
  }
}
