import { Controller } from "stimulus"
import c3 from "c3"

export default class extends Controller {
  static targets = [
    "countBarChart",
    "countLineChart",
    "countPieChart",
    "amountBarChart",
    "amountLineChart",
    "amountPieChart"
  ]

  supportedColors = {
    primary: mApp.getColor('primary'),
    success: mApp.getColor('success'),
    focus: mApp.getColor('focus'),
    danger: mApp.getColor('danger'),
    accent: mApp.getColor('accent'),
    warning: mApp.getColor('warning'),
    info: mApp.getColor('info'),
  }

  connect() {
    this.countBarChart = this.buildBarChart(this.countBarChartTarget, "周转")
    this.countLineChart = this.buildLineChart(this.countLineChartTarget, "支付率")
    this.countPieChart = this.buildPieChart(this.countPieChartTarget, "累计周转")
    this.amountBarChart = this.buildBarChart(this.amountBarChartTarget, "营收")
    this.amountLineChart = this.buildLineChart(this.amountLineChartTarget, "支付率")
    this.amountPieChart = this.buildPieChart(this.amountPieChartTarget, "累计营收")
    this.refresh()
  }

  refresh() {
    const data = JSON.parse(this.element.dataset.json)

    // Load count bar chart
    let barData = data.reduce(((accumulator, it) => {
      if (!!!accumulator[it.date]) accumulator[it.date] = { paid_count: 0, unpaid_count: 0 }
      accumulator[it.date].paid_count += it.paid_count
      accumulator[it.date].unpaid_count += it.unpaid_count
      return accumulator
    }), {})

    barData = Object.keys(barData).map((key) => {
      return { date: key, paid_count: barData[key].paid_count, unpaid_count: barData[key].unpaid_count }
    })

    let options = {
      json: barData,
      keys: {
        x: "date",
        value: [
          "paid_count",
          "unpaid_count"
        ],
      },
      keys: {
        x: "date",
        value: [
          "paid_count",
          "unpaid_count"
        ],
      },
      colors: {
        paid_count: this.supportedColors.success,
        unpaid_count: this.supportedColors.warning,
      },
      names: {
        unpaid_count: "未完结",
        paid_count: "已完结",
      },
    }
    this.countBarChart.load(options)
    this.countBarChart.groups([["paid_count", "unpaid_count"]])

    // Load count line chart
    const lineData = barData.map((it) => {
      const total = it.paid_count + it.unpaid_count
      if (total === 0) {
        return { date: it.date, paid_count_percent: 0 }
      } else {
        return { date: it.date, paid_count_percent: (it.paid_count / total.toFixed(4) * 100).toFixed(2) }
      }
    })

    options = {
      json: lineData,
      colors: {
        paid_count_percent: this.supportedColors.success,
      },
      keys: {
        x: "date",
        value: [
          "paid_count_percent",
        ],
      },
      names: {
        paid_count_percent: "支付率",
      },
    }
    this.countLineChart.load(options)

    // Load count pie chart
    options = {
      json: [
        {
          paid_count_total: data.reduce((sum, it) => (sum += it.paid_count), 0),
          unpaid_count_total: data.reduce((sum, it) => (sum += it.unpaid_count), 0),
        },
      ],
      colors: {
        paid_count_total: this.supportedColors.success,
        unpaid_count_total: this.supportedColors.warning,
      },
      keys: {
        value: ["paid_count_total", "unpaid_count_total"],
      },
      names: {
        paid_count_total: "已收",
        unpaid_count_total: "待收",
      },
    }
    this.countPieChart.load(options)

    // Load amount bar chart
    options = {
      json: data,
      keys: {
        x: "date",
        value: [
          "paid_amount",
          "unpaid_amount"
        ],
      },
      keys: {
        x: "date",
        value: [
          "paid_amount",
          "unpaid_amount"
        ],
      },
      colors: {
        paid_amount: this.supportedColors.success,
        unpaid_amount: this.supportedColors.warning,
      },
      names: {
        unpaid_amount: "未完结",
        paid_amount: "已完结",
      },
    }
    this.amountBarChart.load(options)
    this.amountBarChart.groups([["paid_amount", "unpaid_amount"]])

    // Load amount line chart
    options = {
      json: data,
      colors: {
        paid_amount_percent: this.supportedColors.success,
      },
      keys: {
        x: "date",
        value: [
          "paid_amount_percent",
        ],
      },
      names: {
        paid_amount_percent: "支付率",
      },
    }
    this.amountLineChart.load(options)

    // Load amount pie chart
    options = {
      json: [
        {
          paid_amount_total: data.reduce((sum, it) => (sum += parseFloat(it.paid_amount)), 0),
          unpaid_amount_total: data.reduce((sum, it) => (sum += parseFloat(it.unpaid_amount)), 0),
        },
      ],
      colors: {
        paid_amount_total: this.supportedColors.success,
        unpaid_amount_total: this.supportedColors.warning,
      },
      keys: {
        value: ["paid_amount_total", "unpaid_amount_total"],
      },
      names: {
        paid_amount_total: "已收",
        unpaid_amount_total: "待收",
      },
    }
    this.amountPieChart.load(options)
  }

  buildBarChart(element, title) {
    return c3.generate({
      bindto: element,
      data: {
        order: null,
        json: [],
        type: "bar",
      },
      bar: {
        title: title,
      },
      axis: {
        y: {
          label: title,
          min: 0,
          padding: {
            bottom: 0
          }
        },
        x: {
          type: "timeseries",
          tick: {
            format: "%Y-%m-%d",
            culling: {
              max: 3
            }
          },
        },
      },
    })
  }

  buildLineChart(element, title) {
    return c3.generate({
      bindto: element,
      data: {
        x: "date",
        type: "line",
        json: [],
      },
      line: {
        title: title,
      },
      axis: {
        y: {
          label: title,
          tick: {
            format: function(value) {
              return `${value}%`
            }
          },
          min: 0,
          padding: {
            bottom: 0
          }
        },
        x: {
          type: "timeseries",
          tick: {
            format: "%Y-%m-%d",
            culling: {
              max: 3
            }
          }
        },
      },
      tooltip: {
        format: {
          value: function(value, ratio, id, index) {
            return `${value}%`
          }
        }
      }
    })
  }

  buildPieChart(element, title) {
    return c3.generate({
      bindto: element,
      data: {
        json: [],
        type: "pie",
      }
    })
  }
}
