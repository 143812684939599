import Select2Controller from "./select2_controller"

export default class extends Select2Controller {
  searchParameters(params) {
    const organizationTarget = this.targets.find("organization")

    const term           = params.term
    const organizationId = organizationTarget && organizationTarget.value

    return {
      q: { account_name_or_account_phone_cont: term, organization_id_eq: organizationId },
      page: params.page || 1
    }
  }
}
