import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["month", "tip"]

  connect() {
    this.toggleTip()
  }

  monthChanged() {
    this.toggleTip()
  }

  toggleTip() {
    const date = new Date()

    let month = (date.getMonth() + 1).toString()
    if (month.length < 2) month = `0${month}`
    month = `${date.getFullYear()}-${month}`

    debugger

    if (month == this.monthTarget.value) {
      this.tipTarget.classList.remove('m--hide')
    } else {
      this.tipTarget.classList.add('m--hide')
    }
  }
}

