import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ['preview', 'form']
  static values = ['lot']

  connect() {
  }

  preview(event) {
    const inputs = this.formTarget.querySelectorAll('input, select')
    const form   = { preview: true }
    const path   = '/lots/' + this.element.dataset.lotId + '/spaces/batch_create'

    inputs.forEach(it => {
      let name  = it.name
      let value = it.value
      form[name] = value
    })

    axios.post(path, form).then(resp => this.previewTarget.innerHTML = resp.data)
    event.preventDefault()
    event.stopPropagation()
  }
}
