import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "orders",
    "ordersCount",
    "income",
    "incomeAmount",
    "users",
    "usersCount",
    "subscriptions",
    "subscriptionsAmount",
    "subscriptionsCount",
    "month",
    "lot"
  ]

  connect() {
    this.ordersChart = this.buildChart(
      this.ordersTarget,
      [],
      [],
      mApp.getColor("info")
    )

    this.incomeChart = this.buildChart(
      this.incomeTarget,
      [],
      [],
      mApp.getColor("danger")
    )

    this.usersChart = this.buildChart(
      this.usersTarget,
      [],
      [],
      mApp.getColor("primary")
    )

    this.subscriptionsChart = this.buildChart(
      this.subscriptionsTarget,
      [],
      [],
      mApp.getColor("focus")
    )

    this.refresh()
  }

  refresh() {
    let params = {
      month: this.monthTarget.value,
      lot_id: this.lotTarget.value
    }

    this.doRefresh('/charts/dashboard_orders', params, this.ordersChart)
        .then((data => {
          this.ordersCountTarget.innerText = data.count
        }).bind(this))

    this.doRefresh('/charts/dashboard_income', params, this.incomeChart)
        .then((data => {
          this.incomeAmountTarget.innerText = data.amount
        }).bind(this))

    this.doRefresh('/charts/dashboard_users', params, this.usersChart)
        .then((data => {
          this.usersCountTarget.innerText = data.count
        }).bind(this))

    this.doRefresh('/charts/dashboard_subscriptions', params, this.subscriptionsChart)
        .then((data => {
          this.subscriptionsCountTarget.innerText  = data.count
          this.subscriptionsAmountTarget.innerText = data.amount
        }).bind(this))
  }

  async doRefresh(url, params, chart) {
    const resp = await axios.get(url, { params: params });
    const data = resp.data;
    chart.data.labels = data.labels;
    chart.data.datasets[0].data = data.data;
    chart.update();
    return data;
  }

  buildChart(element, labels, data, color) {
    let target = element.getContext('2d')

    let gradient = target.createLinearGradient(0, 0, 0, 240)
    gradient.addColorStop(0, Chart.helpers.color(color).alpha(1).rgbString())
    gradient.addColorStop(1, Chart.helpers.color(color).alpha(0.2).rgbString())

    let options = {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: null,
              backgroundColor: gradient,
              borderColor: color,
              pointBackgroundColor: Chart.helpers.color("#000000").alpha(0).rgbString(),
              pointBorderColor: Chart.helpers.color("#000000").alpha(0).rgbString(),
              pointHoverBackgroundColor: color,
              pointHoverBorderColor: Chart.helpers.color("#000000").alpha(0.1).rgbString(),
              data: data
            }
          ]
        },
        options: {
          title: { display: !1 },
          tooltips: {
            intersect: true,
            mode: "nearest",
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10
          },
          legend: { display: !1 },
          responsive: !0,
          maintainAspectRatio: !1,
          hover: { mode: "index" },
          scales: {
            xAxes: [
              {
                display: !1,
                gridLines: !1,
                scaleLabel: { display: !0, labelString: "Month" }
              }
            ]
          },
          elements: {
            line: { tension: 1e-7 },
            point: { radius: 4, borderWidth: 12 }
          }
        }
      }

    return new Chart(element, options)
  }
}
