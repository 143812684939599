import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ["container", "content"]

  connect() {
    this.$element = $(this.containerTarget)
    this.$dialog = this.$element.find(".modal-dialog")
  }

  show(event) {
    const { url, title, size = 'md' } = event.currentTarget.dataset
    if (!url) return

    this.$dialog.removeClass("modal-sm modal-md modal-lg")
    this.$dialog.addClass("modal-" + size)

    axios.get(url)
      .then(response => response.data)
      .then(html => {
        this.contentTarget.innerHTML = html
        this.$element.modal('show')
        Inputmask().mask(this.contentTarget.querySelectorAll('input[data-inputmask]'))
        $(".date-range-picker").daterangepicker(
          { autoApply: true, autoUpdateInput: false },
          function (start, end, label) {
            this.element[0].value = `${start.format("YYYY-MM-DD")} ~ ${end.format(
              "YYYY-MM-DD"
            )}`;
            const event = new Event("change");
            this.element[0].dispatchEvent(event);
          }
        );
        $(".date-picker").daterangepicker(
          Object.assign({}, { singleDatePicker: true })
        );
        $(".m-selectpicker").selectpicker();
        $(".lightbox").lightGallery({
          width: "800px",
          height: "600px",
          mode: "lg-fade",
          addClass: "fixed-size",
          counter: true,
          download: false,
          startClass: "",
          enableSwipe: false,
          enableDrag: false,
          speed: 500,
        });
      })
  }
}
