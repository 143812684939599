import {Controller} from "stimulus"
import axios from "axios"

const MAX_SEND_INTERVAL = 120000; // 120 seconds
const SEND_TIME_STORAGE_KEY = 'confirmation_code_send_time';

export default class extends Controller {
  static targets = ['submit', 'phone', 'code', 'link', 'password', 'passwordConfirmation']

  connect() {
    this.linkEnabled = true;

    // Restores remaining counting down
    const lastSendTime = this.getLastSendTime();
    const lastSendElapsed = lastSendTime && Date.now() - lastSendTime.getTime();
    if (lastSendElapsed && lastSendElapsed < MAX_SEND_INTERVAL) {
      const remainingSeconds = Math.ceil((MAX_SEND_INTERVAL - lastSendElapsed) / 1000);
      this.startCountDown(remainingSeconds);
    }
  }

  toggleSubmit() {
    const phone = this.phoneTarget.value
    const code = this.codeTarget.value
    const password = this.passwordTarget.value
    const passwordConfirmation = this.passwordConfirmationTarget.value

    if (!!phone && !!code && !!password && !!passwordConfirmation && password === passwordConfirmation) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  fetchCode() {
    if (!this.linkEnabled) return

    const phone = this.phoneTarget.value
    if (!phone) {
      alert("请输入手机号")
      return;
    }

    this.linkEnabled = false;
    axios
      .get(`/confirmations/new?phone=${phone}`)
      .then((response) => {
        const sendTime = response.data.data.attributes.verification_code_send_at
        const accountId = response.data.data.attributes.account_id

        if (!accountId) {
          alert("账号不存在，请检查手机号是否正确");
          this.linkEnabled = true;
        } else if (!sendTime) {
          alert("验证码发送失败，请稍后重试");
          this.linkEnabled = true;
        } else {
          this.setLastSendTime(new Date(sendTime));
          this.startCountDown(Math.ceil(MAX_SEND_INTERVAL / 1000));
        }
      })
      .catch(() => {
        this.linkEnabled = true;
      })
  }

  startCountDown(seconds) {
    this.seconds = seconds;
    this.linkEnabled = false;
    this.linkTarget.innerText = `已发送（${this.seconds}s）`;
    this.timer = setInterval(() => {
      if (this.seconds > 0) {
        this.linkTarget.innerText = `已发送（${--this.seconds}s）`;
      } else {
        this.stopCountDown();
      }
    }, 1000);
  }

  stopCountDown() {
    this.seconds = 0;
    this.linkEnabled = true;
    this.linkTarget.innerText = '获取短信验证码';
    clearInterval(this.timer);
  }

  getLastSendTime() {
    const str = localStorage.getItem(SEND_TIME_STORAGE_KEY);
    try {
      return str ? new Date(str) : undefined;
    } catch {
      console.error('Parse confirmation_code_send_time error.');
      return undefined;
    }
  }

  setLastSendTime(date) {
    localStorage.setItem(SEND_TIME_STORAGE_KEY, date.toISOString());
  }
}
