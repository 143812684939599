import Select2Controller from "./select2_controller"

export default class extends Select2Controller {
  searchParameters(params) {
    const term = params.term
    const orgId = this.targetValue('org')
    const sectionId = this.targetValue('section')

    const q = {
      name_cont: term,
      organization_id_eq: orgId,
      section_id_eq: sectionId
    }

    const sectionExist = this.controlTarget.dataset.sectionExist
    if (sectionExist === 'false') q.section_id_null = 1

    return {
      q: q,
      page: params.page || 1
    }
  }
}
