import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.fullscreen = false
    this.button = this.addButton()
    this.button.addEventListener('click', this.toggle.bind(this))
  }

  toggle() {
    this.button.classList.contains('la-expand') ? this.expand() : this.compress()
  }

  expand() {
    this.fullscreen = true

    if (this.element.requestFullscreen) {
      this.element.requestFullscreen()
    } else if (this.element.webkitRequestFullscreen) {
      this.element.webkitRequestFullscreen()
    } else if (this.element.msRequestFullscreen) {
      this.element.msRequestFullscreen()
    }

    this.button.classList.remove('la-expand')
    this.button.classList.add('la-compress')
  }

  compress() {
    this.fullscreen = false

    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }

    this.button.classList.remove('la-compress')
    this.button.classList.add('la-expand')
  }

  addButton() {
    const button = document.createElement('i')

    button.classList.add('la', 'la-expand', 'text-dark')
    button.style.zIndex = 9999
    button.style.position = 'absolute'
    button.style.top = '5px'
    button.style.right = '5px'
    button.style.cursor = 'pointer'

    this.element.appendChild(button)
    return button
  }
}