import { Controller } from "stimulus"
import axios from "axios"
import c3 from "c3"
import qs from "qs"

export default class extends Controller {
  static targets = [
    "timerange",
    "lot",
    "dimPaidWithChart",
    "dimStatusChart",
    "dimLotChart"
  ]

  supportedColors = [
    mApp.getColor('primary'),
    mApp.getColor('success'),
    mApp.getColor('focus'),
    mApp.getColor('danger'),
    mApp.getColor('accent'),
    mApp.getColor('warning'),
    mApp.getColor('info'),
  ]

  connect() {
    this.dimPaidWithChart = this.buildChart(this.dimPaidWithChartTarget, '支付方式')
    this.dimStatusChart = this.buildChart(this.dimStatusChartTarget, '状态')
    this.dimLotChart = this.buildChart(this.dimLotChartTarget, '路段')
    this.refresh()
  }

  refresh() {
    let q = {
      created_at_between: this.timerangeTarget.value,
      lot_id_in: this.lotTarget.value
    }

    let q2 = {
      fulfilled_at_between: this.timerangeTarget.value,
      lot_id_in: this.lotTarget.value
    }

    this.doRefresh('/charts/dashboard_orders_paid_with', { q: q2 }, this.dimPaidWithChart)
    this.doRefresh('/charts/dashboard_orders_status', { q: q }, this.dimStatusChart)
    this.doRefresh('/charts/dashboard_orders_lot', { q: q }, this.dimLotChart)
  }

  async doRefresh(url, params, chart) {
    const resp = await axios.get(`${url}?${qs.stringify(params)}`)
    const data = resp.data

    let colors = {}
    Object.keys(data).sort().forEach(((it, index) => {
      colors[it] = this.supportedColors[index]
    }).bind(this))

    chart.load({
      json: [data],
      keys: {
        value: Object.keys(data)
      },
      colors: colors
    })

    return data
  }

  buildChart(element, title) {
    return c3.generate({
      bindto: element,
      data: {
        json: [],
        keys: {
          value: [],
        },
        type: "donut"
      },
      donut: {
        title: title
      }
    })
  }
}
