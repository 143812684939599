import Select2Controller from "./select2_controller"

export default class extends Select2Controller {
  searchParameters(params) {
    const term = params.term
    const orgId = this.targetValue("org")

    return {
      q: { name_cont: term, organization_id_eq: orgId },
      page: params.page || 1
    }
  }
}
