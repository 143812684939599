import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ['control']

  connect() {
    const { placeholder = '请选择', url, selected, allowClear } = this.controlTarget.dataset

    this.$control = $(this.controlTarget)

    this.options = {
      placeholder,
      url,
      selected,
      allowClear,
      // 远程数据始终显示搜索框，本地数据超过一定数目才显示
      minimumResultsForSearch: url ? 0 : 15,
    }

    this.init()
  }

  init() {
    if (!this.options.selected) {
      this.initSelect2()
      return
    }

    const params = { selected: this.options.selected.split(',') }
    axios.get(this.options.url, { params: params })
      .then((response) =>{
        this.$control.append(
          response.data.options.map(it => {
            const $option = $('<option selected></option>');
            $option.attr('value', it.id);
            $option.text(it.text);
            this.addExtraToDataset($option.get(0), it.extra);
            return $option;
          })
        );
      })
      .then(this.initSelect2.bind(this))
      .then(this.dispatchChangeEvent.bind(this, { init: true }))
  }

  initSelect2() {
    const config = {
      language: 'zh-CN',
      placeholder: this.options.placeholder,
      width: '100%',
      allowClear: this.options.allowClear,
      minimumResultsForSearch: this.options.minimumResultsForSearch,
      // See: https://select2.org/programmatic-control/retrieving-selections
      // It is possible to extend the <option> elements representing the current
      // selection(s) with HTML data-* attributes to contain arbitrary data from
      // the source data objects
      templateSelection: (data, _container) => {
        this.addExtraToDataset(data.element, data.extra);
        return data.text;
      }
    }

    if (this.options.url) {
      config.ajax = {
        url: this.options.url,
        dataType: "JSON",
        cache: true,
        delay: 250,
        data: this.searchParameters.bind(this),
        processResults: (data) => {
          return { results: data.options, pagination: { more: data.more } }
        },
      }
    }

    this.$control.select2(config).on("select2:select select2:unselect", this.dispatchChangeEvent.bind(this))
  }

  searchParameters(params) {
    const term = params.term
    const ransack = this.element.dataset.ransack || 'term'

    const str = this.element.dataset.q
    const q = (!!str && JSON.parse(str)) || {}

    q[ransack] = term

    return {
      q: q,
      page: params.page || 1
    }
  }

  /**
   * @deprecated Use `clear` instead.
   */
  refresh() {
    this.clear();
  }

  clear() {
    this.controlTarget.value = null;
    this.dispatchChangeEvent();
  }

  targetValue(name) {
    const target = this.targets.find(name)
    if (!!!target) return

    return target.value
  }

  addExtraToDataset(el, extra) {
    if (typeof extra === 'object' && extra !== null) {
      el.dataset.extra = JSON.stringify(extra);
    }
  }

  dispatchChangeEvent(detail = {}) {
    const event = new Event("change");
    event.detail = detail;
    this.controlTarget.dispatchEvent(event);
  }
}
