import { Controller } from "stimulus"
import c3 from "c3"

export default class extends Controller {
  static targets = [
    "lineChart",
    "pieChart"
  ]

  connect() {
    this.barChart = this.buildBarChart()
    this.pieChart  = this.buildPieChart()
    this.refresh()
  }

  refresh() {
    const data = JSON.parse(this.element.dataset.json)

    let options = {
      json: data,
      keys: {
        x: "date",
        value: ["net_revenue"],
      },
    }
    this.barChart.load(options)

    // Load pie chart
    options = {
      json: [
        {
          wechat_net_revenue: data.reduce((sum, it) => (sum + Number(it.wechat_revenue) - Number(it.wechat_refund)), 0),
          alipay_net_revenue: data.reduce((sum, it) => (sum + Number(it.alipay_revenue) - Number(it.alipay_refund)), 0),
          cash_net_revenue: data.reduce((sum, it) => (sum + Number(it.cash_revenue) - Number(it.cash_refund)), 0),
        },
      ],
      colors: {
        wechat_net_revenue: 'var(--color-wechat)',
        alipay_net_revenue: 'var(--color-alipay)',
        cash_net_revenue: 'var(--color-cash)',
      },
      keys: {
        value: [
          "wechat_net_revenue",
          "alipay_net_revenue",
          "cash_net_revenue"
        ],
      },
      names: {
        wechat_net_revenue: "微信净收款",
        alipay_net_revenue: "支付宝净收款",
        cash_net_revenue: "现金净收款"
      },
    }
    this.pieChart.load(options)
  }

  buildBarChart() {
    return c3.generate({
      bindto: this.lineChartTarget,
      data: {
        type: "bar",
        json: [],
        names: { net_revenue: "总净收款" },
        colors: { net_revenue: 'var(--color-chart-primary)' },
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: "%Y-%m-%d"
          }
        },
        y: {}
      }
    })
  }

  buildPieChart() {
    return c3.generate({
      bindto: this.pieChartTarget,
      data: {
        json: [],
        type: "pie",
      }
    })
  }
}
