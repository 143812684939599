import { Controller } from "stimulus"

export default class extends Controller {
  static values = ['message', 'answer']

  connect() {
    this.element.addEventListener('click', (event) => {
      const message = this.element.dataset.importantConfirmMessage;
      const answer = this.element.dataset.importantConfirmAnswer;
      const isAnswerCorrect = message && answer && window.prompt(message) === answer;
      if (!isAnswerCorrect) {
        Rails.stopEverything(event);
      }
    })
  }
}
