import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["longitude", "latitude", "location"]

  connect() {
    if (typeof window.AMap !== "object") return

    this.createMap()
  }

  createMap() {
    this.map = new AMap.Map("map")
    this.geocoder = new AMap.Geocoder()
    this.marker = new AMap.Marker({ draggable: this.isDraggable() }).on(
      "dragend",
      this.updatePosition.bind(this)
    )

    const position = this.getPosition()
    if (position) {
      this.marker.setPosition(position)
      this.map.add(this.marker)
    }

    const path = this.getPath()
    if (path && path.length > 0) {
      this.line = this.buildLine(path)
      this.map.add(this.line)
      this.editor = this.openEditor()
    }

    this.map.setFitView([this.marker, this.line].filter(it => !!it))

    if (this.isDraggable() && !!!position) {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout((() => this.refresh()).bind(this), 250)
    }
  }

  openEditor() {
    if (!this.isDraggable()) return

    const editor = new AMap.PolyEditor(this.map, this.line)

    editor.open()
    editor.on("addnode", this.updateLocation.bind(this))
    editor.on("adjust", this.updateLocation.bind(this))
    editor.on("removenode", this.updateLocation.bind(this))

    return editor
  }

  refresh() {
    const address = this.getValue("address");

    if (!!!address) return
    if (!!!this.map) return
    if (!!!this.geocoder) return

    this.geocoder.getLocation(
      address,
      ((status, result) => {
        if (status === "complete" && result.geocodes.length) {
          const location = result.geocodes[0].location
          this.marker.setPosition(location)
          this.map.add(this.marker)
          this.map.setFitView(this.marker)
          this.updatePosition()
        }
      }).bind(this)
    )
  }

  updatePosition() {
    const longitude = this.marker.getPosition().lng
    const latitude = this.marker.getPosition().lat
    const path = [
      [longitude - 0.0001, latitude],
      [longitude + 0.0001, latitude]
    ]

    if (this.longitudeTarget) this.longitudeTarget.value = longitude
    if (this.latitudeTarget) this.latitudeTarget.value = latitude

    if (this.line) this.map.remove(this.line)
    if (this.editor) this.editor.close()

    this.line = this.buildLine(path)
    this.map.add(this.line)
    this.editor = this.openEditor()

    this.updateLocation()
  }

  updateLocation() {
    let value = this.line
      .getPath()
      .map(it => `${it.lng} ${it.lat}`)
      .join(" ");
    this.locationTarget.value = value
  }

  getPosition() {
    let longitude = this.getValue("longitude")
    let latitude = this.getValue("latitude")

    if (longitude && latitude) return [longitude, latitude]
  }

  getPath() {
    let location = this.getValue("location")
    if (!!!location) return

    location = location.split(" ").map(it => parseFloat(it))
    return this.slice(location)
  }

  buildLine(path) {
    const line = new AMap.Polyline({
      path: path,
      strokeColor: "#42A6F9",
      strokeWeight: 6,
      strokeOpacity: 0.9,
      zIndex: 50,
      bubble: true
    })

    return line
  }

  getValue(name) {
    let value = this.data.get(name)
    if (value) return value

    let target = this.targets.find(name)
    if (target) return target.value
  }

  isDraggable() {
    let target = this.targets.find("address")

    if (target) {
      return true
    } else {
      return false
    }
  }

  slice(arr, it = []) {
    if (arr.length === 0) return it
    return this.slice(arr, [...it, arr.splice(0, 2)])
  }
}
