import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["all", "item", "action"]

  connect() {
    this.disableAction()
  }

  selectAll() {
    const checked = this.allTarget.checked
    this.itemTargets.forEach((item) => item.checked = checked)

    this.toggleAction()
  }

  select() {
    const checked = this.itemTargets.every((item) => item.checked)
    this.allTarget.checked = checked

    this.toggleAction()
  }

  toggleAction() {
    const enable = this.itemTargets.find((item) => item.checked && !item.disabled)

    if (enable) {
      this.enableAction()
    } else {
      this.disableAction()
    }
  }

  disableAction() {
    this.actionTargets.forEach((it) => {
      it.disabled = true
      it.style = "display:none;"
    })
  }

  enableAction() {
    this.actionTargets.forEach((it) => {
      it.disabled = false
      it.style = ""
    })
  }
}
