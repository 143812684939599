import { Controller } from "stimulus"
import date from 'date-and-time'

export default class extends Controller {
  static targets = [
    "map"
  ]

  connect() {
    if (typeof window.AMap !== "object") return

    this.createMap()
  }

  async createMap() {
    const data = JSON.parse(this.element.dataset.path)
    const path = data.map(it => [it.longitude, it.latitude])

    this.map = new AMap.Map(this.mapTarget, {
      mapStyle: 'amap://styles/81645b302d3c534f1ddef8f004da4ce8'
    })

    AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], (PathSimplifier, $) => {
      var pathSimplifierIns = new PathSimplifier({
        zIndex: 100,
        autoSetFitView: true,
        map: this.map,
        renderOptions: {
          renderAllPointsIfNumberBelow: 50
        },
        getPath: (pathData, pathIndex) => pathData.path,
        getHoverTitle: (pathData, pathIndex, pointIndex) => `时间: ${date.format(new Date(data[pointIndex].time), 'HH:mm')}`
      })

      window.pathSimplifierIns = pathSimplifierIns

      //设置数据
      pathSimplifierIns.setData([{
        name: '线路',
        path: path
      }])

      const navigator = pathSimplifierIns.createPathNavigator(0, { loop: true, speed: 1000 })
      navigator.start()
    })
  }
}
