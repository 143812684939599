import { Controller } from "stimulus"
import Axios from "axios"

export default class extends Controller {
  static targets = ['items', 'dropzone', 'file', 'tip', 'indicator']

  connect() {
    this.model = this.data.get("model")
    this.id = this.data.get("id")
    this.name = this.data.get("name")
    this.retrieve()
    this.dropzone()
  }

  retrieve() {
    const params = {
      model: this.model,
      id: this.id,
      name: this.name
    }

    Axios.get('/attachments', { params: params }).then((it => {
      this.attachments = it.data.data.map(it => it.attributes)
      this.render()
    }).bind(this))
  }

  render() {
    let items = this.attachments.map((it, index) => {
      return `
        <div class="m-widget4__item">
          <div class="m-widget4__img m-widget4__img--icon">
            <img src="${it.url}">
          </div>
          <div class="m-widget4__info">
            <span class="m-widget4__text">
              ${it.filename}
            </span>
          </div>
          <div class="m-widget4__ext">
            <a href="javascript:void(0)" class="m-widget4__icon" data-index="${index}" data-action="attachments-uploader#remove">
              <i class="la la-trash"></i>
            </a>
          </div>
        </div>
      `
    }).join('')

    let hidden_inputs = this.attachments.map(((it, index) => {
      return `
        <input type="hidden" name="${this.model.toLowerCase()}[${this.name}][][blob_id]" value="${it.blob_id}" />
        <input type="hidden" name="${this.model.toLowerCase()}[${this.name}][][id]" value="${it.id}" />
      `
    }).bind(this)).join('')

    this.itemsTarget.innerHTML = `
      <div class="m-widget4">
        ${items}
      </div>
      ${hidden_inputs}
    `
  }

  upload(files) {
    this.tipTarget.classList.add('m--hide')
    this.indicatorTarget.classList.remove('m--hide')

    ;([...files]).forEach((file => {
      Axios.post('/uploads', { upload: { filename: file.name, prefix: 'attachments' } }).then(it => {
        const url = it.data.data.attributes.upload_url
        const headers = { 'Content-Type': '' }
        fetch(url, { method: 'PUT', body: file, headers: headers })
          .then(it => it.json())
          .then(this.uploadSuccess.bind(this))
          .catch(this.uploadFailure.bind(this))
      })
    }).bind(this))
  }

  uploadSuccess(data) {
    this.attachments.push(data)
    this.render()
    this.tipTarget.classList.remove('m--hide')
    this.indicatorTarget.classList.add('m--hide')
  }

  uploadFailure(error) {
    this.tipTarget.classList.remove('m--hide')
    this.indicatorTarget.classList.add('m--hide')
    alert(error)
  }

  remove(e) {
    const index = e.currentTarget.dataset.index
    this.attachments.splice(index, 1)
    this.render()
  }

  dropzone() {
    const dropzone = this.dropzoneTarget
    const fileInput = this.fileTarget
    const highlight = () => dropzone.classList.add('highlight')
    const unhighlight = () => dropzone.classList.remove("highlight")
    const preventDefaults = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }

    dropzone.addEventListener("click", () => fileInput.click())

    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropzone.addEventListener(eventName, preventDefaults, false)
    })

    ;['dragenter', 'dragover'].forEach(eventName => {
      dropzone.addEventListener(eventName, highlight, false)
    })

    ;['dragleave', 'drop'].forEach(eventName => {
      dropzone.addEventListener(eventName, unhighlight, false)
    })

    dropzone.addEventListener('drop', (e => {
      const files = e.dataTransfer.files
      this.upload(files)
    }).bind(this))

    fileInput.addEventListener('change', (e => {
      this.upload(fileInput.files)
    }).bind(this))
  }
}
