import Select2Controller from "./select2_controller"

export default class extends Select2Controller {
  searchParameters(params) {
    const term = params.term
    const lotId = this.targetValue('lot')
    const orgId = this.targetValue('org')

    const q = {
      name_cont: term,
      lot_id_eq: lotId,
      organization_id_eq: orgId
    }

    const deviceInstalled = this.controlTarget.dataset.deviceInstalled
    if (deviceInstalled === 'false') q.device_id_null = 1
    console.log('Welcome ...')

    return {
      q: q,
      page: params.page || 1
    }
  }
}
