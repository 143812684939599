import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'input']

  connect() {
  }

  submit() {
    this.formTarget.submit()
  }

  select() {
    this.inputTarget.click()
  }
}
