import {Controller} from "stimulus"
import {Context} from "@stimulus/core/dist/src/context";

const CLS_OVERFLOW_LEFT = 'overflow-left';
const CLS_OVERFLOW_RIGHT = 'overflow-right';

export default class extends Controller {
    constructor(context) {
        super(context);
        this._updateScrollState = this._updateScrollState.bind(this);
    }

    connect() {
        this.config = {
            fixLeft: 0,
            fixRight: 0,
            ...JSON.parse(this.element.dataset.tableScrollConfig || '{}'),
        };

        this.scrollContainerElement = this.element.querySelector('.table-scroll-container');
        this.tableElement = this.element.querySelector('table');

        this.scrollContainerElement.addEventListener('scroll', this._updateScrollState);
        this.resizeObserver = new ResizeObserver(this._updateScrollState);
        this.resizeObserver.observe(this.scrollContainerElement)

        this._updateScrollState();
        this._updateFixedColumns();
    }

    disconnect() {
        this.resizeObserver.disconnect();
        this.scrollContainerElement.removeEventListener('scroll', this._updateScrollState);
    }

    _updateFixedColumns() {
        const {fixLeft, fixRight} = this.config;
        const grid = new TableGrid(this.tableElement);

        // 设置滚动的左右边缘，用于调整阴影位置
        let leftEdge = 0, rightEdge = 0;
        if (this.tableElement.rows.length > 0) {
            if (fixLeft > 0) {
                const cell = grid.getCellByIndex(0, fixLeft - 1);
                if (cell) {
                    const {offsetLeft, offsetWidth} = getOffsetRect(cell);
                    leftEdge = offsetLeft + offsetWidth;
                }
            }

            if (fixRight > 0) {
                const colCount = grid.getColCount();
                const cell = grid.getCellByIndex(0, colCount - fixRight);
                if (cell) {
                    const {offsetRight, offsetWidth} = getOffsetRect(cell);
                    rightEdge = offsetRight + offsetWidth;
                }
            }
        }
        this.element.style.setProperty('--left-edge', `${leftEdge}px`);
        this.element.style.setProperty('--right-edge', `${rightEdge}px`);

        // 设置固定单元格的属性
        for (const row of this.tableElement.rows) {
            for (const cell of row.cells) {
                const colIndex = grid.getCellIndex(cell).colIndex;
                const colCount = grid.getColCount();

                const setStickyStyle = () => {
                    cell.style.position = 'sticky';
                    cell.style.backgroundColor = "white";
                }
                if (colIndex < fixLeft) {
                    setStickyStyle();
                    const left = getOffsetRect(cell).offsetLeft;
                    cell.style.left = `${left}px`;
                }
                if (colCount - colIndex - 1 < fixRight) {
                    setStickyStyle();
                    const right = getOffsetRect(cell).offsetRight;
                    cell.style.right = `${right}px`;
                }
            }
        }
    }

    _updateScrollState() {
        const el = this.element;
        const scrollEl = this.scrollContainerElement;

        if (scrollEl.scrollLeft > 0) {
            el.classList.add(CLS_OVERFLOW_LEFT);
        } else {
            el.classList.remove(CLS_OVERFLOW_LEFT);
        }

        const scrollRight = scrollEl.scrollWidth - scrollEl.scrollLeft - scrollEl.clientWidth;
        if (Math.abs(scrollRight) > 0.5) {
            el.classList.add(CLS_OVERFLOW_RIGHT);
        } else {
            el.classList.remove(CLS_OVERFLOW_RIGHT);
        }
    }
}

/**
 *
 * @param el HTMLElement
 */
function getOffsetRect(el) {
    const parentRect = el.parentElement.getBoundingClientRect();
    const rect = el.getBoundingClientRect();
    return {
        offsetLeft: rect.left - parentRect.left,
        offsetRight: rect.right - parentRect.right,
        offsetWidth: rect.width,
    };
}

class TableGrid {
    constructor(tableElement) {
        this.grid = [];
        this.tableElement = tableElement;
        this._initialize();
    }

    getCellByIndex(rowIndex, colIndex) {
        return this.grid[rowIndex][colIndex];
    }

    getColCount() {
        if (this.colCount === undefined) {
            const sorted = this.grid.sort((a, b) => b.length - a.length);
            this.colCount = sorted.length > 0 ? sorted[0].length : 0;
        }

        return this.colCount;

    }

    getCellIndex(cell) {
        const col = cell.cellIndex;
        const row = cell.parentElement.rowIndex;

        let colIndex = col;
        const isOccupied = () => {
            const gridRow = this.grid[row];
            if (!gridRow) return false;

            const gridCell = gridRow[colIndex];
            return gridCell && gridCell !== cell;
        }
        while (isOccupied()) {
            colIndex++;
        }

        return {
            rowIndex: row,
            colIndex,
        }
    }

    _initialize() {
        for (const row of this.tableElement.rows) {
            for (const cell of row.cells) {
                this._fillCell(cell);
            }
        }
    }

    /**
     *
     * @param cell HTMLTableCellElement
     */
    _fillCell(cell) {
        const {rowIndex, colIndex} = this.getCellIndex(cell);
        const {rowSpan, colSpan} = cell;

        for (let i = 0; i < rowSpan; i++) {
            const curRow = rowIndex + i;
            if (!this.grid[curRow]) {
                this.grid[curRow] = [];
            }

            for (let j = 0; j < colSpan; j++) {
                const curCol = colIndex + j;
                this.grid[curRow][curCol] = cell;
            }
        }
    }
}