import { Controller } from "stimulus"
import c3 from "c3"

export default class extends Controller {
  connect() {
    c3.generate({
      bindto: '#chart',
      data: {
        columns: [
          ['营收', 30, 200, 100, 400, 150, 250],
          ['周转量', 50, 20, 10, 40, 15, 25]
        ],
        labels: true
      },
      axis: {
        y: {
          show: false
        }
      }
    })
  }
}
